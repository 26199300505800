import { ILocation } from "@sp-crm/core";
import { getMarkerIcon } from "components/map/map-common";
import "maplibre-gl/dist/maplibre-gl.css";
import * as React from "react";
import { MapboxEvent, Marker } from "react-map-gl";
import { locationFor } from "../listable-community-utility";
import { ListableCommunity } from "../props";

const lng = (loc: ILocation) => loc.loc[0];
const lat = (loc: ILocation) => loc.loc[1];

export const NextgenMapMarker: React.FC<{
    community: ListableCommunity;
    isHovered: boolean;
    selectCommunity?: (c: ListableCommunity) => void;
}> = props => {
    const { community, isHovered, selectCommunity } = props;
    const location = locationFor(community);
    const onClick = React.useCallback(
        (e: MapboxEvent<MouseEvent>) => {
            e.originalEvent.stopPropagation();
            if (selectCommunity) {
                selectCommunity(community);
            }
        },
        [community, selectCommunity],
    );
    if (!location) {
        return null;
    }

    return (
        <Marker
            longitude={lng(location)}
            latitude={lat(location)}
            anchor="bottom"
            onClick={onClick}>
            <img
                src={getMarkerIcon(community.contractStatus)}
                className={isHovered ? "w-10 h-16" : "w-6 h-10"}
            />
        </Marker>
    );
};
