import { StarIcon } from "@heroicons/react/24/solid";
import { ILocation } from "@sp-crm/core";
import { Spinner } from "components/ui/spinner";
import { LngLatBounds } from "maplibre-gl";
import * as React from "react";
import { LngLatBoundsLike, MapRef, Marker } from "react-map-gl";
import { assetRoot } from "util/version";

export const getMarkerIcon = (contractStatus: string) => {
    switch (contractStatus) {
        case "pending":
            return assetRoot + "/static/images/marker-icon-orange-2x.png";
        case "oneOff":
            return assetRoot + "/static/images/marker-icon-green-2x.png";
        case "active":
            return assetRoot + "/static/images/marker-icon-violet-2x.png";
        default:
            return assetRoot + "/static/images/marker-icon-2x.png";
    }
};

export const useSupressEvents = () => {
    const _shouldSuppressEvents = React.useRef<boolean>(false);
    const reenableTimeout = React.useRef<NodeJS.Timeout | null>(null);
    const suppressEvents = React.useCallback(() => {
        if (reenableTimeout.current) {
            clearTimeout(reenableTimeout.current);
        }
        _shouldSuppressEvents.current = true;
    }, []);
    const reenableEvents = React.useCallback((delay: number) => {
        if (reenableTimeout.current) {
            clearTimeout(reenableTimeout.current);
        }
        reenableTimeout.current = setTimeout(() => {
            _shouldSuppressEvents.current = false;
        }, delay);
    }, []);
    const shouldProcessEvents = React.useCallback(
        () => !_shouldSuppressEvents.current,
        [],
    );
    return [shouldProcessEvents, suppressEvents, reenableEvents] as const;
};

const lng = (loc: ILocation) => loc.loc[0];
const lat = (loc: ILocation) => loc.loc[1];

export const GeoCenter: React.FC<{
    location: ILocation;
}> = props => {
    const { location } = props;
    return (
        <Marker longitude={lng(location)} latitude={lat(location)}>
            <div
                className={`mx-auto flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-full bg-yellow-100`}>
                <StarIcon className="w-8 h-8 text-yellow-500" />
            </div>
        </Marker>
    );
};

export const MapLoadingIndicator: React.FC<{ isLoading: boolean }> = props => {
    if (props.isLoading) {
        return (
            <div className="top-0 left-0 right-0 p-8 flex justify-around">
                <div className="bg-white p-2 relative rounded shadow flex space-x-2">
                    <p className="text-sm text-gray-500">Updating</p>
                    <Spinner />
                </div>
            </div>
        );
    }
    return null;
};

const isValidMapBounds = (bounds: LngLatBounds) => {
    if (!bounds) {
        return false;
    }
    if (bounds.getEast() === bounds.getWest()) {
        return false;
    }
    if (bounds.getNorth() === bounds.getSouth()) {
        return false;
    }
    if (
        isNaN(bounds.getEast()) ||
        isNaN(bounds.getWest()) ||
        isNaN(bounds.getNorth()) ||
        isNaN(bounds.getSouth())
    ) {
        return false;
    }
    return true;
};

/**
 * useAutoFit will zoom the map to fit the bounds if `shouldFit` is `true`
 * @param shouldFit `true` if the map should fit the bounds, `false` if user has moved the map and should not auto-move
 * @param mapRef reference to map
 * @param mapBounds bounds to fit
 * @param supressEvents function to suppress events
 * @param reenableEvents function to re-enable events
 */
export const useAutoFit = (
    shouldFit: boolean,
    mapRef: React.MutableRefObject<MapRef>,
    mapBounds: LngLatBounds,
    supressEvents: () => void,
    reenableEvents: (delay: number) => void,
) => {
    React.useEffect(() => {
        if (shouldFit && mapRef.current && isValidMapBounds(mapBounds)) {
            supressEvents();
            try {
                mapRef.current.fitBounds(
                    mapBounds as unknown as LngLatBoundsLike,
                    {
                        padding: 50,
                        maxZoom: 15,
                        duration: 700,
                    },
                    {
                        automaticOperationType: "fitBounds",
                    },
                );
            } catch {
                // Have been unable to repro this issue, but about 1x/wk we get an error of "Invalid LngLat object: (NaN, NaN)"
                // There is discussion on this, but none of the proposed fixes solve anything for us.
                // https://github.com/mapbox/mapbox-gl-js/issues/8732
            }
            reenableEvents(800);
        }
    }, [mapBounds, shouldFit, mapRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useAutoResizeMapContainer = (
    wrapperDiv: React.MutableRefObject<HTMLDivElement>,
    fixedMapDiv: React.MutableRefObject<HTMLDivElement>,
    suppressEvents: () => void,
    mapRef: React.MutableRefObject<MapRef>,
    reenableEvents: (delay: number) => void,
) => {
    React.useEffect(() => {
        const updateMapPosition = () => {
            if (!wrapperDiv.current || !fixedMapDiv.current) {
                return;
            }
            suppressEvents();
            const mapParent = wrapperDiv.current;
            const mapParentRect = mapParent.getBoundingClientRect();
            const mapParentTop = mapParentRect.top;
            const winHeight = window.innerHeight;
            const newMapTop = mapParentTop > 0 ? mapParentTop : 0;
            fixedMapDiv.current.style.height = `${winHeight - newMapTop}px`;
            if (mapRef.current) {
                mapRef.current.getMap().resize();
            }
            reenableEvents(800);
        };
        window.addEventListener("resize", updateMapPosition);
        window.addEventListener("scroll", updateMapPosition);
        updateMapPosition();
        return () => {
            window.removeEventListener("resize", updateMapPosition);
            window.removeEventListener("scroll", updateMapPosition);
        };
    }, [wrapperDiv.current, fixedMapDiv.current, mapRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useMapEvents = (
    shouldFit: boolean,
    wrapperDiv: React.MutableRefObject<HTMLDivElement>,
    fixedMapDiv: React.MutableRefObject<HTMLDivElement>,
    mapRef: React.MutableRefObject<MapRef>,
    mapBounds: LngLatBounds,
) => {
    const [shouldProcessEvents, suppressEvents, reenableEvents] = useSupressEvents();
    useAutoResizeMapContainer(
        wrapperDiv,
        fixedMapDiv,
        suppressEvents,
        mapRef,
        reenableEvents,
    );
    useAutoFit(shouldFit, mapRef, mapBounds, suppressEvents, reenableEvents);
    return shouldProcessEvents;
};
